<template>
    <BaseScene name="stage-actor-view" @leave="OnLeaveScene" @enter="OnEnterScene">

        <div class="lighting-containers">
            <div 
                class="lighting-container" 
                v-for="(lighting, key) in lightings" 
                :key="lighting.src"
                :style="{'background-image': `url('${lighting.src}')`}"
                :class="{visible: activeLighting == key}"
            ></div>
        </div>

        <div class="panel-bg" :class="{visible: panelVisible}">
            <div class="panel">
                <p>{{ cms.get('translations').get('intensity') }}</p>
                <div class="lightings">
                    <div 
                        class="lighting" 
                        @click="setLighting(key)"
                        v-for="(lighting, key) in lightings"
                        :key="key"
                    >
                        <div class="light">
                            <img :src="require('@/assets/images/panel-button-on.png')" v-if="activeLighting == key">
                            <img :src="require('@/assets/images/panel-button-off.png')" v-else>
                        </div>
                        <div class="label">{{ cms.get('translations').get('lighting-' + lighting.title) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <img :src="require('@/assets/images/panel.png')"> -->
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import { Howl } from 'howler';
import ResourceManager from "@/classes/resource-manager";

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseScene,
    },
    mounted() {

    },
    data() {
        return {
            lightings: {
                1: {
                    src: ResourceManager.Get("grandstandlights.1"),
                    sound: ResourceManager.Get("moods.soft"),
                    title: 'soft'
                },
                2: {
                    src: ResourceManager.Get("grandstandlights.2"),
                    sound: ResourceManager.Get("moods.medium"),
                    title: 'medium'
                },
                3: {
                    src: ResourceManager.Get("grandstandlights.3"),
                    sound: ResourceManager.Get("moods.strong"),
                    title: 'strong'
                }
            },
            activeLighting: '1',
            panelVisible: false,
            activeSound: null
        };
    },
    methods: {
        setLighting(lighting) {
            this.activeLighting = lighting;

            const sound = new Howl({
                src: [ResourceManager.Get("shorts.spotlight")],
                sprite: {
                    short: [350, 2000]
                },
                volume: 1
            });

            sound.play('short');

            if (this.activeSound != null && this.activeSound.playing()) {
                this.activeSound.stop();
            }  

            this.activeSound = new Howl({
                src: [this.lightings[this.activeLighting].sound],
                volume: 1
            });

            this.activeSound.play();
        },
        OnEnterScene() {
            setTimeout(() => {
                this.panelVisible = true;
            }, 1000);
        },
        OnLeaveScene() {
            this.panelVisible = false;

            if (this.activeSound != null) {
                this.activeSound.stop();
            }              
        }
    }
}
</script>

<style lang="scss">
.scene-stage-actor-view {
    .lighting-containers {
        .lighting-container {
            width: 100%;
            height: 100%;
            position: fixed;
            opacity: 0;
            transition: 1s;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            &.visible {
                opacity: 1;
            }
        }
    }

    .panel-bg {
        width: 225px;
        height: 393px;
        left: 20px;
        top: -450px;
        position: fixed;
        background-image: url('../../assets/images/panel-small.svg');
        transition: .35s;

        &.visible {
            top: -15px;
        }

        .panel {
            position: absolute;
            bottom: 20px;
            left: 20px;
            width: 100%;

            p {
                margin-bottom: 20px;
            }

            .lightings {
                display: flex;
                flex-direction: column;

                .lighting {
                    margin-bottom: 1px;
                    color: white;
                    font-size: 14px;
                    cursor: pointer;
                    display: flex;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .label {
                        margin-top: 2px;
                    }

                    .light {
                        font-size: 0px;
                        line-height: 0px;
                    }
                }
            }            

            p {
                color: white;
                font-weight: bold;
                font-size: 14px;
                text-transform: uppercase;
                margin-left: 10px;            
            }
        }

    }
}

@media (max-width: 800px) and (max-height: 400px) {
    .scene-stage-actor-view {
        .panel-bg {
            zoom: 0.8;

            .panel {
                bottom: 12px;
            }
            
            &.visible {
                top: -165px;
            }
        }
    }
}
</style>